import { studentLibraryAssignmentFilter } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import type {
  StudentLibraryCubit,
  StudentLibraryAssignmentSortingStrategy,
  StudentLibraryAssignmentSortingOrder,
  StudentLibraryAssignmentFilter,
} from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import {
  ValidLibraryObjectActionState,
  type ValidLibraryObject,
} from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import classNames from 'classnames'
import {
  BreakoutButton,
  BreakoutIconButton,
} from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { ChevronRight } from 'components/icons/ChevronRight'
import { CircleX } from 'components/icons/CircleX'
import { ClockIcon } from 'components/icons/Clock'
import { Search } from 'components/icons/Search'
import { SortIcon } from 'components/icons/Sort'
import { TriangleRightFillIcon } from 'components/icons/TriangleRightFIll'
import { Spinner } from 'components/Spinner'
import { useRootStore } from 'hooks/rootStore'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, type MouseEvent, useEffect } from 'react'
import { EmptyListPlaceholder } from './EmptyListPlaceholder'
import {
  LibraryObjectState,
  SectionPaymentMethod,
} from '@breakoutlearning/firebase-repository/types'
import { useRepository } from 'hooks/auth'
import { ChatBubbleIcon } from 'components/icons/ChatBubble'
import { useTranslationTyped } from 'i18n/i18n'
import { useBreakoutUser } from 'hooks/profile'
import { useCanvasMode } from 'hooks/use-canvas-mode'

// passing current strategy explicity or else it will not re-render on change
const SortableHeaderLabel = ({
  cubit,
  label,
  currentStrategy,
  sortingStrategy,
  currentOrder,
}: {
  cubit: StudentLibraryCubit
  label: string
  currentOrder: StudentLibraryAssignmentSortingOrder
  currentStrategy: StudentLibraryAssignmentSortingStrategy
  sortingStrategy: StudentLibraryAssignmentSortingStrategy
}) => {
  const isCurrentStrategy = currentStrategy === sortingStrategy
  return (
    <th className="!rounded-t-none !pt-7" scope="col">
      <div
        className="flex cursor-pointer flex-row items-center gap-1"
        onClick={() => {
          if (isCurrentStrategy && currentOrder === 'desc') {
            // switch back to default sorting
            cubit.changeSortingStrategy('default', 'asc')
          } else if (isCurrentStrategy) {
            // switch order
            cubit.changeSortingStrategy(sortingStrategy, 'desc')
          } else {
            // switch strategy and order
            cubit.changeSortingStrategy(sortingStrategy, 'asc')
          }
        }}
      >
        <strong
          className={classNames({
            'text-label-medium text-on-surface-var': !isCurrentStrategy,
            'text-body-medium text-on-surface': isCurrentStrategy,
          })}
        >
          {label}
        </strong>
        <TriangleRightFillIcon
          size={12}
          className={classNames('rotate-90', {
            'rotate-90': isCurrentStrategy && currentOrder === 'asc',
            '!rotate-270': isCurrentStrategy && currentOrder === 'desc',
            'fill-on-surface-disabled': !isCurrentStrategy,
            'fill-fixed-accent-color': isCurrentStrategy,
          })}
        />
      </div>
    </th>
  )
}

const TableRow = ({
  libraryObject,
  isLast,
  cubit,
}: {
  libraryObject: ValidLibraryObject
  isLast: boolean
  cubit: StudentLibraryCubit
}) => {
  const { canvasMode: ltiMode } = useCanvasMode()
  const getFormattedTime = (date: Date) => {
    const dayStr = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date)
    const timeStr =
      DateTime.fromJSDate(date, { zone: 'local' })
        .setZone(DateTime.local().zoneName)
        .toLocaleString(DateTime.TIME_SIMPLE) +
      ' ' +
      DateTime.local().toFormat('ZZZZ')
    return {
      dayStr,
      timeStr,
    }
  }
  const { expiresAt } = libraryObject.assignment
  const { scheduledAt } = libraryObject.roomState.data

  const expiresAtFormatted = expiresAt ? getFormattedTime(expiresAt) : null
  const scheduledAtFormatted = scheduledAt
    ? getFormattedTime(scheduledAt)
    : null

  const { navigateTo } = useRootStore()
  const canNavigateToAssignmentPage =
    !libraryObject.assignment.assignedAtIsInFuture

  const { showNewScheduling } = useRepository().featureFlags.data

  const canceledOrExpired = [
    LibraryObjectState.canceled,
    LibraryObjectState.expired,
  ].includes(libraryObject.libraryObjectState)

  const showChatButton =
    cubit.isLoaded &&
    showNewScheduling &&
    !canceledOrExpired &&
    !libraryObject.requiresPayment &&
    libraryObject.roomState.isNotEmpty

  return (
    <tr
      data-testid={`library-object-list-item-${libraryObject.libraryObjectId}`}
      className={classNames('!border-surface bg-surface-bright', {
        '!border-b-8': !isLast,
        '!border-none': isLast,
        'cursor-pointer': canNavigateToAssignmentPage,
      })}
      onClick={
        canNavigateToAssignmentPage
          ? () => {
              const roomStateId = libraryObject.roomState.id
              const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
              navigateTo(route, {
                assignmentId: libraryObject.assignment.id,
                sectionId: libraryObject.section.id,
                roomStateId: libraryObject.roomState.id,
              })
            }
          : undefined
      }
    >
      {/* course (section) */}
      <TableRowClassNameCell libraryObject={libraryObject} />
      {/* instructor */}
      <TableRowInstructorCell libraryObject={libraryObject} />
      {/* experience (slide deck logo/name/teaser) */}
      <td>
        <div className="flex flex-row items-center">
          <div className="aspect-square h-10 p-2">
            {libraryObject.slideDeckImageURL ? (
              <img
                src={libraryObject.slideDeckImageURL}
                className="max-h-full max-w-full object-cover"
                alt={libraryObject.slideDeckName}
                aria-hidden
              />
            ) : (
              <div className="h-full w-full bg-surface-dim" />
            )}
          </div>
          <div className="flex flex-col">
            <strong className="line-clamp-1 text-body-medium leading-[1.05rem] text-on-surface-var">
              {libraryObject.slideDeckName}
            </strong>
            <strong className="line-clamp-1 text-label-large leading-[1.05rem] text-on-surface">
              {libraryObject.slideDeckTeaser}
            </strong>
          </div>
        </div>
      </td>
      {/* due date */}
      {!ltiMode && (
        <td className="!pl-5">
          {expiresAtFormatted && (
            <div className="flex flex-col">
              <strong className="text-label-medium">
                {expiresAtFormatted.dayStr}
              </strong>
              <strong className="text-surface-on-surface-var text-body-medium">
                {expiresAtFormatted.timeStr}
              </strong>
            </div>
          )}
        </td>
      )}
      {/* scheduledDate */}
      <td className="!pl-5">
        {scheduledAtFormatted && (
          <div className="flex flex-col">
            <strong className="text-label-medium">
              {scheduledAtFormatted.dayStr}
            </strong>
            <strong className="text-surface-on-surface-var text-body-medium">
              {scheduledAtFormatted.timeStr}
            </strong>
          </div>
        )}
      </td>
      {
        /* chat */
        showNewScheduling && (
          <td className="!pl-5">
            {showChatButton && (
              <div className="flex items-center justify-center">
                <ChatBubbleButton libraryObject={libraryObject} />
              </div>
            )}
          </td>
        )
      }
      <td
        className="!pl-5"
        style={{
          borderRadius: '0 16px 16px 0',
        }}
      >
        {/* pr-2 prevents the outline from peeking from under the sticky header */}
        <div className="flex h-full w-full items-center justify-center pr-2">
          <ActionForLibraryObject
            libraryObject={libraryObject}
            isHeader={false}
          />
        </div>
      </td>
    </tr>
  )
}

const TableRowClassNameCell = observer(function TableRowClassNameCell({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  return (
    <td
      style={{
        borderRadius: '16px 0 0 16px',
      }}
      className="!pl-5"
    >
      <div className="flex flex-col justify-center">
        <strong className="text-label-medium">
          {libraryObject.section.data.className}
        </strong>
      </div>
    </td>
  )
})

const TableRowInstructorCell = observer(function TableRowInstructorCell({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  return (
    <td className="!pl-5">
      <div className="flex flex-col justify-center">
        <strong className="text-label-medium">
          {libraryObject.section.instructor.fullName}
        </strong>
      </div>
    </td>
  )
})

export const AssignmentTable = observer(function AssignmentTable({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  const { showNewScheduling } = useRepository().featureFlags.data
  const { tt } = useTranslationTyped()
  const { canvasMode: ltiMode } = useCanvasMode()

  if (cubit.libraryObjects.length === 0) return <EmptyListPlaceholder />

  const libraryObjectList = cubit.filteredSortedLibraryObjects

  let columnCount = showNewScheduling ? 7 : 6
  if (ltiMode) columnCount -= 1

  return (
    <div className="flex-grow overflow-auto rounded-2xl bg-surface px-7 pb-7">
      <BreakoutTable
        className={classNames('relative table-fixed', {
          'min-h-full': libraryObjectList.length === 0,
        })}
      >
        <thead className="sticky top-0 z-20 bg-surface">
          <tr>
            <td colSpan={columnCount}>
              <div className="flex w-full flex-row gap-4 pt-7">
                <BreakoutTextInput
                  autoFocus
                  name="filter"
                  onChange={(value) => {
                    cubit.changeSearchTerm(value.target.value)
                  }}
                  LeadingIcon={Search}
                  className="flex-grow"
                  inputClassName="text-body-medium border font-medium placeholder-surface-on-surface-disabled"
                  kind="tertiary"
                  aria-label={tt.library.search_experiences()}
                  placeholder={tt.library.search_experiences()}
                  clearIcon
                />
                <FilterAssignmentsButton cubit={cubit} />
              </div>
            </td>
          </tr>
          <tr>
            <SortableHeaderLabel
              cubit={cubit}
              label={tt.library.course()}
              sortingStrategy={'sectionName'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <SortableHeaderLabel
              cubit={cubit}
              label={tt.library.instructor()}
              sortingStrategy={'instructorName'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <SortableHeaderLabel
              cubit={cubit}
              label={tt.library.experience()}
              sortingStrategy={'experienceName'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            {!ltiMode && (
              <SortableHeaderLabel
                cubit={cubit}
                label={tt.library.due_date()}
                sortingStrategy={'expiresAt'}
                currentStrategy={cubit.sortingStrategy}
                currentOrder={cubit.sortingOrder}
              />
            )}
            <SortableHeaderLabel
              cubit={cubit}
              label={tt.library.session_date()}
              sortingStrategy={'scheduledAt'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            {showNewScheduling && (
              <th
                className="!rounded-t-none !pt-7 text-body-medium text-on-surface-var"
                scope="col"
              >
                <div className="w-full text-center">{tt.library.chat()}</div>
              </th>
            )}
            <th
              className="!rounded-t-none !pt-7 text-body-medium text-on-surface-var"
              scope="col"
            >
              <div className="w-full text-center">{tt.library.action()}</div>
            </th>
          </tr>
        </thead>
        <tbody data-testid="library-object-list" className="overflow-y-auto">
          {libraryObjectList.length === 0 && <EmptyListState />}
          {libraryObjectList.map((libraryObject, i) => (
            <TableRow
              key={libraryObject.libraryObjectId}
              libraryObject={libraryObject}
              isLast={i === libraryObjectList.length - 1}
              cubit={cubit}
            />
          ))}
        </tbody>
      </BreakoutTable>
    </div>
  )
})

function EmptyListState() {
  const { tt } = useTranslationTyped()
  return (
    <tr>
      <td colSpan={6}>
        <div className="flex flex-col items-center justify-center gap-2 rounded-2xl bg-surface p-7">
          <Search className="stroke-fixed-accent-color" size={50} />
          <div className="my-1 text-title-large text-on-surface">
            {tt.instructor_library.no_results_found()}
          </div>
          <div className="text-center text-body-large text-on-surface-var">
            {tt.instructor_library.no_results_found_description_1()}
          </div>
        </div>
      </td>
    </tr>
  )
}

const ChatBubbleButton = observer(function ChatBubbleButton({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  const rootStore = useRootStore()

  const user = useBreakoutUser()
  const notificationsCount =
    user.notifications.getChatNotificationsForAssignmentCount(
      libraryObject.section.id,
      libraryObject.assignment.id
    )

  return (
    <BreakoutIconButton
      kind="secondary"
      size="small"
      icon={<ChatBubbleIcon size={12} />}
      badgeCount={notificationsCount || undefined}
      onClick={(e) => {
        e.stopPropagation()
        rootStore.navigateTo(
          'assignmentWithRoom',
          {
            assignmentId: libraryObject.assignment.id,
            sectionId: libraryObject.section.id,
            roomStateId: libraryObject.roomState.id,
          },
          {
            action: 'chat',
          }
        )
      }}
    />
  )
})

const Label = ({
  text,
  type,
}: {
  text: string
  type: StudentLibraryAssignmentFilter
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-center gap-1 whitespace-nowrap px-4 py-2 text-label-medium'
      )}
    >
      {type !== 'all' && (
        <div
          className={classNames('h-2 min-h-2 w-2 min-w-2 rounded-full', {
            'bg-fixed-grey': type === 'future',
            'bg-fixed-orange': type === 'current',
            'bg-core-error': type === 'past',
          })}
        ></div>
      )}
      <span>{text}</span>
    </div>
  )
}

const SelectedLabel = ({ text }: { text: string }) => {
  const { tt } = useTranslationTyped()
  return (
    <div className="flex flex-row items-center justify-center gap-4">
      <span>{tt.library.filter_prefix({ text })}</span>
      <SortIcon size={15} />
    </div>
  )
}

const DEFAULT_FILTER: StudentLibraryAssignmentFilter = 'all'

const FilterAssignmentsButton = observer(function FilterAssignmentsButton({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  const { tt } = useTranslationTyped()
  const store = useRootStore()

  const filterFromUrl = store.router.queryParams?.filter

  const filterFromUrlIsInvalid =
    typeof filterFromUrl !== 'string' ||
    !studentLibraryAssignmentFilter.includes(
      filterFromUrl as StudentLibraryAssignmentFilter
    )

  const filter = filterFromUrlIsInvalid
    ? DEFAULT_FILTER
    : (filterFromUrl as StudentLibraryAssignmentFilter)

  useEffect(() => {
    // When the page mounts, set the filter appropriately
    cubit.changeAssignmentFilter(filter)
  }, [cubit, filter])

  const handleFilterChange = useCallback(
    (value: StudentLibraryAssignmentFilter) => {
      store.updateQueryParams({ filter: value }, { replaceHistory: true })
      cubit.changeAssignmentFilter(value)
    },
    [store, cubit]
  )

  return (
    <BreakoutSelect<StudentLibraryAssignmentFilter>
      className="min-w-[215px]"
      kind="tertiary"
      value={filter}
      onChange={handleFilterChange}
      aria-label={tt.library.filter_assignments()}
      showChevron={false}
      options={[
        {
          label: <Label text={tt.library.all_assignments()} type="all" />,
          selectedLabel: <SelectedLabel text={tt.library.all_assignments()} />,
          value: 'all',
        },
        {
          label: <Label text={tt.library.future_assignments()} type="future" />,
          selectedLabel: (
            <SelectedLabel text={tt.library.future_assignments()} />
          ),
          value: 'future',
        },
        {
          label: <Label text={tt.library.open_assignments()} type="current" />,
          selectedLabel: <SelectedLabel text={tt.library.open_assignments()} />,
          value: 'current',
        },
        {
          label: <Label text={tt.library.past_assignments()} type="past" />,
          selectedLabel: <SelectedLabel text={tt.library.past_assignments()} />,
          value: 'past',
        },
      ]}
    />
  )
})

const ActionForLibraryObject = observer(function ActionForLibraryObject({
  libraryObject,
  isHeader,
}: {
  libraryObject: ValidLibraryObject
  isHeader: boolean
}) {
  const store = useRootStore()
  const { tt } = useTranslationTyped()

  const navigateWithAction = useCallback(
    (
      actionState: ValidLibraryObjectActionState,
      event: MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation()
      const roomStateId = libraryObject.roomState.id
      const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
      store.navigateTo(
        route,
        {
          assignmentId: libraryObject.assignment.id,
          sectionId: libraryObject.section.id,
          roomStateId: libraryObject.roomState.id,
        },
        {
          action: actionState.toString(),
        }
      )
    },
    [libraryObject, store]
  )

  const actionState = libraryObject.actionState

  switch (actionState) {
    case ValidLibraryObjectActionState.loading: {
      return <Spinner size={1.875} />
    }
    case ValidLibraryObjectActionState.enroll: {
      const { priceInDollars } = libraryObject.slideDeck

      const buttonText = (() => {
        switch (libraryObject.section.data.sectionPaymentMethod) {
          case SectionPaymentMethod.token:
            return tt.student_library.enroll_price({ price: priceInDollars })
          case SectionPaymentMethod.sectionPassStripe:
            return tt.student_library.enroll_with_section_pass()
          case SectionPaymentMethod.sectionPassCoupon:
            return tt.student_library.enroll_with_section_pass()
          case SectionPaymentMethod.sectionPassStripeCoupon:
            return tt.student_library.enroll_with_section_pass()
        }
      })()

      return (
        <BreakoutButton
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(ValidLibraryObjectActionState.enroll, e)
          }}
        >
          {buttonText}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.availableOn: {
      const assignedAt = libraryObject.assignment.data.assignedAt

      const within24Hours = assignedAt
        ? DateTime.fromJSDate(assignedAt).diff(DateTime.now()).as('hours') < 24
        : undefined

      let displayedDateTime
      if (assignedAt) {
        const assignedAtDateTime = DateTime.fromJSDate(assignedAt)

        if (within24Hours) {
          displayedDateTime = assignedAtDateTime.toLocaleString(
            DateTime.TIME_SIMPLE
          )
        } else {
          displayedDateTime = assignedAt.toLocaleDateString()
        }
      }

      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <strong className="text-label-medium">
            {within24Hours
              ? tt.library.available_at()
              : tt.library.available_on()}
          </strong>
          <strong className="text-body-medium text-on-surface-var">
            {displayedDateTime}
          </strong>
        </div>
      )
    }
    case ValidLibraryObjectActionState.joinGroup: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.join_group()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.pending: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <ClockIcon size={15} />
          <strong className="text-body-medium text-on-surface">
            {tt.library.actions.pending()}
          </strong>
        </div>
      )
    }
    case ValidLibraryObjectActionState.scheduleSession: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(ValidLibraryObjectActionState.scheduleSession, e)
          }}
        >
          {tt.library.actions.schedule_session()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.completeQuiz: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.complete_quiz()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.sessionScheduled: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <ClockIcon size={15} />
          <strong className="text-center text-body-medium text-on-surface">
            {tt.library.actions.session_scheduled()}
          </strong>
        </div>
      )
    }
    case ValidLibraryObjectActionState.joinSession: {
      return (
        <BreakoutButton
          kind="primary"
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.join_session()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.suspended: {
      return (
        <BreakoutButton
          kind="primary"
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.continue_session()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.startSession: {
      return (
        <BreakoutButton
          size={!isHeader ? 'small' : 'medium'}
          kind="primary"
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.start_session()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.viewResults: {
      return (
        <BreakoutButton
          icon={<ChevronRight size={15} />}
          iconOnRight={true}
          size={!isHeader ? 'small' : 'medium'}
          kind={isHeader ? 'primary' : 'tertiary'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {tt.library.actions.view_results()}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.experienceExpired: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <CircleX size={15} className="stroke-core-error" />
          <strong className="text-body-medium text-core-error">
            {tt.library.actions.experience_expired()}
          </strong>
        </div>
      )
    }
    case ValidLibraryObjectActionState.canceled: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <CircleX size={15} className="stroke-core-error" />
          <strong className="text-body-medium text-core-error">
            {tt.library.actions.canceled()}
          </strong>
        </div>
      )
    }
  }
})

export const PriorityActionHeader = observer(function PriorityActionHeader({
  libraryObject,
  actionState,
}: {
  libraryObject: ValidLibraryObject
  actionState: ValidLibraryObjectActionState
}) {
  const { t, tt } = useTranslationTyped()
  return (
    <div className="mb-5 flex w-full flex-row items-center gap-5 rounded-[20px] bg-surface px-7 py-4">
      <div className="flex flex-col justify-center">
        <strong className="text-body-medium text-on-surface-var">
          {tt.library.next_action()}
        </strong>
        <strong className="text-title-large">
          {t(`library.actions.header.${actionState}`)}
        </strong>
      </div>
      <div className="w-1 min-w-1 self-stretch bg-fixed-accent-color"></div>
      <div className="flex min-w-[150px] flex-col text-body-medium">
        <strong>{libraryObject.section.data.className}</strong>
        <strong className="text-on-surface-var">
          {libraryObject.instructor.fullName}
        </strong>
      </div>
      <div className="flex flex-grow flex-row gap-1">
        {libraryObject.slideDeckImageURL && (
          <div className="aspect-square h-10 w-10 p-2">
            <img
              src={libraryObject.slideDeckImageURL}
              className="h-full w-full object-cover"
              alt={libraryObject.slideDeck.data.slideDeckName}
              aria-hidden
            />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <strong className="line-clamp-1 text-body-medium text-on-surface-var">
            {libraryObject.slideDeck.data.slideDeckName}
          </strong>
          {libraryObject.slideDeck.data.slideDeckTeaser && (
            <strong className="line-clamp-1 text-label-large">
              {libraryObject.slideDeck.data.slideDeckTeaser}
            </strong>
          )}
        </div>
      </div>
      <div className="flex h-full items-center justify-center">
        <ActionForLibraryObject libraryObject={libraryObject} isHeader={true} />
      </div>
    </div>
  )
})
