import { createContext, useContext } from 'react'
import { observable, type ObservableMap } from 'mobx'

type QAToolsContextType = {
  enabled: boolean
  tools: ObservableMap<string, { label: string; callback: () => void }>
  register: (name: string, label: string, callback: () => void) => void
  unregister: (name: string) => void
  changeLabel: (name: string, label: string) => void
}

export const QAToolsContext = createContext<QAToolsContextType | null>(null)

const noopTools = {
  enabled: false,
  tools: observable.map(),
  register: () => {},
  unregister: () => {},
  changeLabel: () => {},
}

export const activeTools = {
  enabled: false,
  tools: observable.map(),
  register(name: string, label: string, callback: () => void) {
    this.tools.set(name, { label, callback })
  },
  unregister(name: string) {
    this.tools.delete(name)
  },
  changeLabel(name: string, label: string) {
    const tool = this.tools.get(name)
    if (tool) {
      const newTool = { ...tool, label }
      this.tools.set(name, newTool)
    }
  },
}

export function useQATools() {
  const context = useContext(QAToolsContext)

  if (!context) {
    return noopTools
  }
  return context
}
