/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, m } from 'framer-motion'
import { useRootStore } from 'hooks/rootStore'
import { useEffect, useRef, useState } from 'react'
import { reaction } from 'mobx'
import sha1 from 'sha1'
import { NotFoundPage } from 'pages/application/NotFoundPage'

type TransitionConfig = {
  initial: any
  animate: any
  exit: any
  transition: any
}
const transitions: Record<string, TransitionConfig> = {}
transitions.empty = {
  initial: undefined,
  animate: undefined,
  exit: undefined,
  transition: undefined,
}
transitions.home = {
  initial: { left: '100%' },
  animate: { left: '0%' },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}

transitions.slidein = {
  initial: { left: '100%' },
  animate: { left: '0%' },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}

transitions.podcasts = {
  initial: { left: '100%' },
  animate: { left: '0%' },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}
transitions.meeting = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}

transitions.fadein = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
}

transitions.loginWithToken = transitions.empty
transitions.ltiConfiguration = transitions.empty

const transitionMap: Record<string, Record<string, TransitionConfig>> = {
  meeting: {
    home: transitions.slidein,
    assignment: transitions.slidein,
  },
  home: {
    avTest: transitions.slidein,
  },
  avTest: {
    profile: transitions.fadein,
  },
}

export const MobxRouter = () => {
  const store = useRootStore()
  const ref = useRef<string>('')
  const router = store.router
  const initialComponent =
    router.currentRoute && (router.currentRoute.component as JSX.Element)
  const [component, setComponent] = useState<JSX.Element | undefined>(
    initialComponent
  )
  const [transitionConfig, setTransitionConfig] = useState<TransitionConfig>(
    transitions.empty
  )

  useEffect(() => {
    return reaction(
      () => router.currentRoute,
      (route) => {
        if (!route) return
        const newComponent = route.component as JSX.Element
        if (!newComponent) {
          setComponent(undefined)
          return
        }

        setComponent((current) => {
          if (current === newComponent) return current
          return newComponent
        })

        if (!route.title) return
        let newConfig = transitions[route.title] || transitions.slidein

        if (ref.current !== route.title) {
          const current = ref.current
          const next = route.title
          if (transitionMap[next] && transitionMap[next][current]) {
            newConfig = transitionMap[next][current]
          }
        }

        ref.current = route.title

        setTransitionConfig((current) => {
          if (current === newConfig) return current
          return newConfig
        })
      }
    )
  }, [router, ref])

  if (!component) {
    return (
      <div className="relative h-full w-full">
        <NotFoundPage />
      </div>
    )
  }

  const typehash = sha1(component.type)
  return (
    <AnimatePresence mode="wait" initial={false}>
      {component && (
        <m.div
          className="relative h-full w-full"
          key={typehash}
          initial={transitionConfig.initial} // { left: '100%' }}
          animate={transitionConfig.animate} // { left: '0%' }}
          exit={transitionConfig.exit} // { opacity: 0 }}
          transition={transitionConfig.transition} // { duration: 0.2 }}
        >
          {component}
        </m.div>
      )}
    </AnimatePresence>
  )
}
