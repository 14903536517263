import type { ScalarsType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { FullPageSpinner } from 'components/Spinner'
import { inProduction } from 'config/environment'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useDialogs } from 'hooks/dialogs'
import { useTranslationTyped } from 'i18n/i18n'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { EditAssignmentDialog } from 'pages/instructor/slide_deck/AssignDialogV3/EditAssignmentDialog'
import { GroupingMethodDetails } from 'pages/instructor/slide_deck/GroupingMethodDetails'
import { useState } from 'react'

type ScalarKey = keyof Exclude<ScalarsType, undefined>

const GradingPreviewBox = observer(function GradingPreviewBox() {
  const assignmentCubit = useInstructorAssignmentCubit()

  const scalars = assignmentCubit.assignment.data.assignmentGradingScalars

  const { t } = useTranslationTyped()

  if (!scalars) return null

  const keys: ScalarKey[] = [
    'quizScore',
    'attendanceScore',
    'participationScore',
    'totalRubricScore',
  ]

  const translations = {
    quizScore: t('lti.configuration.scalars.quizScore'),
    attendanceScore: t('lti.configuration.scalars.attendanceScore'),
    participationScore: t('lti.configuration.scalars.participationScore'),
    totalRubricScore: t('lti.configuration.scalars.totalRubricScore'),
  }

  const colors = {
    quizScore: 'bg-core-success',
    attendanceScore: 'bg-core-error',
    participationScore: 'bg-blue-500',
    totalRubricScore: 'bg-fixed-accent-color',
  }

  const values = new Map(Object.entries(scalars))
  const presentKeys = new Set(Object.keys(scalars))

  return (
    <div>
      <div className="my-2 flex flex-row gap-3">
        {keys.map((key) => {
          if (!presentKeys.has(key) || scalars[key] === 0) return null

          return (
            <span key={key} className="flex flex-row items-center gap-1">
              <span
                className={classNames(
                  'inline-block h-[6px] w-[6px] rounded-full',
                  colors[key]
                )}
              ></span>
              <strong className="text-label-medium">{translations[key]}</strong>
            </span>
          )
        })}
      </div>
      <div className="my-2 flex flex-row gap-3 ">
        {keys.map((key) => {
          const value = values.get(key)
          if (!presentKeys.has(key) || scalars[key] === 0 || !value) return null

          const percentage = Math.round(value * 100)

          // 1% looks bad, 3% is the moment the rounder corners start looking ok
          // The browser handles us giving us over 100%
          const barWidth = percentage < 3 ? 3 : percentage

          return (
            <div
              key={key}
              style={{
                width: barWidth + '%',
              }}
            >
              <div
                className={classNames(
                  'inline-block h-[6px] w-full rounded-full',
                  colors[key]
                )}
              ></div>
              <div className="text-center text-label-medium">{percentage}%</div>
            </div>
          )
        })}
      </div>
    </div>
  )
})
const AssignmentPreview = observer(function AssignmentPreview() {
  const assignmentCubit = useInstructorAssignmentCubit()
  const { tt } = useTranslationTyped()

  const slideDeck = assignmentCubit.slideDeck

  if (!assignmentCubit.allDataLoaded) return

  const assignment = assignmentCubit.assignment

  const assignedAt = assignment.assignedAt
    ? DateTime.fromJSDate(assignment.assignedAt)
    : null
  const expiresAt = assignment.expiresAt
    ? DateTime.fromJSDate(assignment.expiresAt)
    : null

  const hasGrading = assignmentCubit.assignment.data.assignmentGradingScalars

  const scoreIs0 = assignment.data.ltiMaxScore === 0

  return (
    <div className="flex flex-col gap-2 px-2 text-body-large">
      <div className="mb-3 flex flex-row items-center gap-3 px-2 py-3">
        <div className="pl-3">
          <img
            className="max-h-5 max-w-5"
            src={slideDeck.data.slideDeckImageURL}
            alt={slideDeck.data.slideDeckName}
          />
        </div>
        <div>
          <div className="text-body-medium text-on-surface-var">
            {slideDeck.data.slideDeckName}
          </div>
          <div className="text-label-large">
            {slideDeck.data.slideDeckTeaser}
          </div>
        </div>
      </div>

      <hr className="my-2" />

      <div className="grid grid-cols-2 gap-2">
        {assignment.data.groupingType === AssignmentGroupingType.manual && (
          <>
            <div className="text-label-large">
              {tt.lti.configuration.desired_group_size()}
            </div>
            <div className="text-right">
              {tt.lti.configuration.students({
                count: assignment.data.groupingSizeMaximum || 0,
              })}
            </div>
            <div className="text-label-large">
              {tt.lti.configuration.grouping_method()}
            </div>
            <div className="text-right">
              {tt.lti.configuration.grouping_method_self_grouping()}
            </div>
          </>
        )}
        {assignment.data.groupingType ===
          AssignmentGroupingType.automaticRandom && (
          <>
            <div className="text-label-large">
              {tt.lti.configuration.desired_group_size()}
            </div>
            <div className="text-right">
              {tt.lti.configuration.students({
                count: assignment.data.groupingSize || 0,
              })}
            </div>
            <div className="text-label-large">
              {tt.lti.configuration.grouping_method()}
            </div>
            <div className="text-right">
              {tt.lti.configuration.grouping_method_automatic_randomized_grouping()}
            </div>
          </>
        )}
      </div>
      <GroupingMethodDetails
        groupingMethod={assignment.data.groupingType}
        expiresAt={expiresAt}
        assignedAt={assignedAt}
        hideTitle
      />
      <hr />
      <div>
        <div className="grid grid-cols-2">
          <div className="text-label-large">
            {tt.lti.configuration.grading()}
          </div>
          <div className="text-right text-label-large">
            {assignment.hasGrading
              ? tt.lti.configuration.enabled()
              : tt.lti.configuration.disabled()}
          </div>
        </div>
        <GradingPreviewBox />
        {hasGrading && scoreIs0 && (
          <div className="mt-2 rounded-xl bg-core-error px-4 py-2 text-core-on-error">
            <div className="flex flex-row items-center gap-2">
              <TriangleAlertIcon className="inline" size={14} />
              <div className="text-label-large">
                {tt.lti.configuration.score_0_header()}
              </div>
            </div>
            <div className="my-1 text-body-medium">
              {tt.lti.configuration.score_0_paragraph_1()}
            </div>
            <div className="text-body-medium">
              {tt.lti.configuration.score_0_paragraph_2()}
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export const AssignmentConfiguration = observer(
  function AssignmentConfiguration() {
    const assignmentCubit = useInstructorAssignmentCubit()
    const sectionCubit = useInstructorSectionCubit()
    const { showDialog } = useDialogs()
    const { t } = useTranslationTyped()
    const [causeError, setCauseError] = useState(false)

    if (!assignmentCubit.assignment) {
      return <FullPageSpinner />
    }

    if (causeError) {
      // @ts-expect-error this is a test
      omgThisIsAnError()
    }

    return (
      <div className="lti-configuration min-h-screen w-screen">
        <div className="">
          <img
            src="/assets/images/logo.png"
            width={64}
            className="absolute right-8 top-8"
            alt={t('breakout_learning')}
          />
        </div>
        <div className="lti-rocket flex min-h-screen w-full flex-col items-start justify-center">
          <div className="ml-[5vw] max-w-[450px]">
            <div className="mb-5">
              <h1 className="text-headline-large">
                {t('lti.configuration.confirm_assignment_configuration')}
              </h1>
              <div className="text-body-large">
                {t(
                  'lti.configuration.confirm_assignment_configuration_description'
                )}
              </div>
            </div>
            <div className="py mb-4 rounded-2xl bg-white px-5 py-8">
              <AssignmentPreview />
            </div>
            <div className="flex flex-row items-center justify-between gap-2">
              <BreakoutButton
                data-testid="add-to-class-button"
                className="bg-transparent hover:bg-transparent"
                kind="secondary"
                size="medium"
                onClick={() => {
                  showDialog(() => {
                    return (
                      <EditAssignmentDialog
                        cubit={assignmentCubit}
                        assignment={assignmentCubit.assignment}
                      />
                    )
                  })
                  return
                }}
              >
                {t('lti.configuration.confirm_assignment_configuration_edit')}
              </BreakoutButton>
              <BreakoutButton
                data-testid="add-to-class-button"
                kind="accent"
                size="large"
                onClick={async () => {
                  await assignmentCubit.markAsActive()
                  await sectionCubit.markAsInProgress()
                }}
              >
                {t('lti.configuration.confirm')}
              </BreakoutButton>
            </div>
            {!inProduction() && (
              <div className="mt-4 text-right">
                <BreakoutButton
                  data-testid="add-to-class-button"
                  kind="error"
                  size="small"
                  onClick={async () => {
                    setCauseError(true)
                  }}
                >
                  Cause Error
                </BreakoutButton>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)
