import { BreakoutButtonLink } from 'components/design-system/BreakoutButton'
import { useQueryParams } from 'hooks/router'
import { useTranslationTyped } from 'i18n/i18n'
import { observer } from 'mobx-react-lite'

export const LtiErrorPage = observer(function LtiErrorPage() {
  const params = useQueryParams()

  const errorText = ((params ? params['text'] : '') || '').toString()

  return <LtiErrorView errorText={errorText} />
})

const mailtoNewline = '%0D%0A'

export const LtiErrorView = observer(function LtiErrorView({
  errorText,
}: {
  errorText: string
}) {
  const { t, tt } = useTranslationTyped()
  const lines = errorText ? errorText.split('\\n') : []

  return (
    <div className="lti-configuration min-h-screen w-screen">
      <div className="">
        <img
          src="/assets/images/logo.png"
          width={64}
          className="absolute right-8 top-8"
          alt={t('breakout_learning')}
        />
      </div>
      <div className="lti-error flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[550px]">
          <div className="mb-5 flex flex-row">
            <div className="max-w-[400px] pr-5">
              <h1 className="mb-3 text-headline-large">
                {tt.lti.configuration.error_header()}
              </h1>
              {lines.length > 0 && (
                <>
                  <h1 className="text-body-large">
                    {tt.lti.configuration.error_subheader()}
                  </h1>
                  <div className="my-4 rounded-2xl bg-core-tertiary p-4 text-label-large">
                    <ul className="list-disc pl-5">
                      {lines.map((line, index) => (
                        <li key={index}>{line}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
              <div>
                <BreakoutButtonLink
                  target="_top"
                  href={`mailto:integrations@breakoutlearning.com?subject=Canvas Configuration Error&body=Canvas Integration Issues:${mailtoNewline} • ${mailtoNewline} ${lines.join(mailtoNewline)}`}
                  kind="accent"
                  size="medium"
                  className="!py-2"
                >
                  {tt.lti.configuration.error_contact_support()}
                </BreakoutButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
