import { PublicRoute } from 'config/routes'
import { useAuthContext, useRepository } from 'hooks/auth'
import { StoreProvider, useRootStore } from 'hooks/rootStore'
import { LoginPage } from 'pages/login/LoginPage'
import React, { useEffect, useState } from 'react'
import { RootStore } from 'store/root'
import { LazyMotion, domAnimation } from 'framer-motion'
import { MobxRouter } from './MobxRouter'
import { Theme } from './Theme'
import { I18nWrapper } from 'i18n/components'
import { UserContextProvider } from 'hooks/profileContext'
import { SettingsContextProvider } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { NotFoundPage } from 'pages/application/NotFoundPage'
import { StorageUrlContextProvider } from 'components/contexts/StorageUrlContextProvider'
import { DialogMount } from 'components/dialogs/DialogMount'
import { DialogContextProvider } from 'components/dialogs/DialogContextProvider'
import { ObjectUrlContextProvider } from 'components/contexts/ObjectUrlContextProvider'
import { AuthedRedirectHandler } from './AuthedRedirectHandler'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import classNames from 'classnames'
import { QAToolsProvider } from 'qatools/QAToolsProvider'
import { inBeta, inProduction } from 'config/environment'
import { NewVersionBannerEntry } from './NewVersionBannerEntry'

const UnauthedMount = observer(function UnauthedMount() {
  const rootStore = useRootStore()

  const route = rootStore.router.currentRoute

  if (route instanceof PublicRoute) {
    return route.component as React.ReactNode
  } else if (route) {
    return (
      <SettingsContextProvider>
        <DialogContextProvider>
          <LoginPage />
          <DialogMount />
        </DialogContextProvider>
      </SettingsContextProvider>
    )
  } else {
    return <NotFoundPage />
  }
})

function AuthedMount() {
  return (
    <UserContextProvider>
      <SettingsContextProvider>
        <DialogContextProvider>
          <MobxRouter />
          <DialogMount />
          <AuthedRedirectHandler />
        </DialogContextProvider>
      </SettingsContextProvider>
    </UserContextProvider>
  )
}

export const Entrypoint = observer(function Entrypoint() {
  const { entrypointLocked } = useRepository()
  const [rootStore] = useState(() => new RootStore())

  const { authenticated, initialized } = useAuthContext()

  useEffect(() => {
    // if we are on logout page and not authenticated, navigate to home, since we are logged out
    setTimeout(() => {
      if (
        rootStore.router.currentRoute?.title === 'logout' &&
        !authenticated &&
        initialized
      ) {
        rootStore.navigateTo('home')
      }
    }, 100)
  }, [authenticated, initialized, rootStore])

  useEffect(() => {
    // convert hash based routes to history based routes
    const { hash, pathname } = window.location
    if (hash) {
      window.location.replace(pathname + hash.replace('#', ''))
    }
  })

  const { canvasMode } = useCanvasMode()

  if (!initialized) return null
  if (!rootStore.hasStartedRouter) return <NotFoundPage />

  const showAuthedMount = authenticated && !entrypointLocked

  return (
    <>
      <QAToolsProvider enabled={!inProduction() && !inBeta()}>
        <StoreProvider value={rootStore}>
          <Theme>
            <I18nWrapper>
              <ObjectUrlContextProvider>
                <StorageUrlContextProvider>
                  <LazyMotion features={domAnimation}>
                    <div className="h-dvh w-dvw">
                      <div
                        className={classNames(
                          'relative box-border flex h-full w-full flex-col items-center justify-center ',
                          !canvasMode && 'md:p-2'
                        )}
                      >
                        <NewVersionBannerEntry />
                        {showAuthedMount && <AuthedMount />}
                        {!showAuthedMount && <UnauthedMount />}
                      </div>
                    </div>
                  </LazyMotion>
                </StorageUrlContextProvider>
              </ObjectUrlContextProvider>
            </I18nWrapper>
          </Theme>
        </StoreProvider>
      </QAToolsProvider>
    </>
  )
})
