import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PurchaseDialogBody } from './PurchaseDialogBody'
import { EnrollDialogBody } from './EnrollDialogBody'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useBreakoutUser } from 'hooks/profile'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useRootStore } from 'hooks/rootStore'
import { Spinner } from 'components/Spinner'
import { useTranslation } from 'react-i18next'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useDialogs } from 'hooks/dialogs'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { type SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import { BreakoutRadioButton } from 'components/design-system/BreakoutRadioButton'
import { TicketIcon } from 'components/icons/Ticket'
import { useRepository } from 'hooks/auth'
import { useTranslationTyped } from 'i18n/i18n'
import { DialogTopSectionWithIcon } from 'components/dialogs/DialogTopSectionWithIcon'

function ProcessingView() {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex h-full flex-1 flex-col items-center justify-center gap-5 text-center">
        <div className="text-title-large">
          {t('student_assignment.processing_payment')}
        </div>
        <Spinner />
      </div>
    </>
  )
}

export const PurchaseOrEnrollDialog = observer(function PurchaseOrEnrollDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const repo = useRepository()
  const rootStore = useRootStore()
  const user = useBreakoutUser()
  const [showProcessing, setShowProcessing] = useState(false)

  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'enroll')
    return () => {
      cubit.onDialogMountUnmount('unmount', 'enroll')
    }
  })

  const queryParams = rootStore.router.queryParams
  const isPostSuccess = queryParams?.payment === 'success'

  const slideDeckPrice = cubit.slideDeck.data.slideDeckPrice
  const tokensRequired = slideDeckPrice - user.availableTokens

  const DialogContents = useMemo(() => {
    if (showProcessing) return ProcessingView
    if (tokensRequired > 0 || cubit.section.usesSectionPass)
      return PurchaseDialogBody
    return EnrollDialogBody
  }, [cubit.section.usesSectionPass, showProcessing, tokensRequired])

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined
    // when we have a successful payment, but the tokens are not yet updated
    // show a processing view, but time it out after 10 seconds
    if (isPostSuccess && tokensRequired > 0) {
      repo.logEvent('post_success_processing_started')
      setShowProcessing(true)
      timeout = setTimeout(() => {
        repo.logEvent('post_success_processing_timed_out')
        setShowProcessing(false)
      }, 10000)
    } else {
      setShowProcessing(false)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isPostSuccess, tokensRequired, repo])

  useEffect(() => {
    repo.logEvent('purchase_or_enroll_dialog_opened')
  }, [repo])

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col items-center justify-between gap-2"
      ignoreBackdropClick
    >
      <DialogCloseButton />
      <DialogContents cubit={cubit} />
    </Dialog>
  )
})

export const RedeemCouponDialog = observer(function RedeemCouponDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const repo = useRepository()
  const [formError, setFormError] = useState<string>()
  const [isRedeeming, setIsRedeeming] = useState(false)
  const { popDialog } = useDialogs()
  const { t } = useTranslation()

  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'enroll')
    return () => cubit.onDialogMountUnmount('unmount', 'enroll')
  })

  const redeemCoupon = useCallback(
    async (couponCode: string) => {
      setFormError(undefined)
      if (!couponCode) return setFormError('coupon code required')
      setIsRedeeming(true)
      let redeemed = false
      try {
        const result = await cubit.redeemSectionPassCoupon(couponCode)
        if (typeof result === 'string') setFormError(result)
        redeemed = result === true
        if (!redeemed && typeof result !== 'string')
          setFormError('invalid coupon code')
      } catch (e) {
        setFormError('invalid coupon code')
      } finally {
        setIsRedeeming(false)
        if (redeemed) {
          toast.success(t('student_assignment.coupon_redeemed'))
          popDialog()
        }
      }
    },
    [cubit, popDialog, t]
  )

  useEffect(() => {
    repo.logEvent('redeem_coupon_dialog_opened')
  }, [repo])

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Dialog
      size="md"
      innerClassName="flex flex-col items-center justify-between gap-2"
      ignoreBackdropClick
    >
      <DialogCloseButton />
      <h1 className="mb-4px text-center text-headline-large">
        {t('student_assignment.redeem_coupon_code')}
      </h1>
      <div className="flex flex-1 items-center justify-center">
        <p className="text-center text-body-medium">
          {t('student_assignment.redeeming_coupon_code_description')}
        </p>
      </div>
      <div className="flex w-full flex-1 flex-col justify-end gap-2">
        <BreakoutTextInput
          kind="secondary"
          ref={inputRef}
          disabled={isRedeeming}
          placeholder={t('student_assignment.enter_coupon_code')}
          error={
            formError ? { message: formError, type: 'validate' } : undefined
          }
          onChange={() => setFormError(undefined)}
        />
        <BreakoutButton
          fullWidth
          size="large"
          loading={isRedeeming}
          disabled={isRedeeming}
          onClick={() => {
            repo.logEvent('redeem_coupon_button_clicked')
            redeemCoupon(inputRef.current?.value || '')
          }}
        >
          {t('student_assignment.apply')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})

export const SelectCouponOrStripeDialog = observer(
  function SelectCouponOrStripeDialog({
    cubit,
    showStripeDialog,
    showCouponDialog,
  }: {
    cubit: StudentAssignmentCubit
    showStripeDialog: VoidFunction
    showCouponDialog: VoidFunction
  }) {
    const { popDialog } = useDialogs()
    useEffect(() => {
      cubit.onDialogMountUnmount('mount', 'enroll')
      return () => cubit.onDialogMountUnmount('unmount', 'enroll')
    })
    return (
      <Dialog
        size="sm"
        innerClassName="flex flex-col items-center justify-evenly gap-2"
        ignoreBackdropClick
      >
        <DialogCloseButton />
        <h1 className="mb-4px text-center text-headline-large">
          Select Purchase Method
        </h1>
        <div className="flex flex-col gap-3">
          <BreakoutButton
            fullWidth
            size="medium"
            onClick={() => {
              popDialog()
              setTimeout(showStripeDialog, 0)
            }}
          >
            Pay With Stripe
          </BreakoutButton>
          <BreakoutButton
            fullWidth
            size="medium"
            onClick={() => {
              popDialog()
              setTimeout(showCouponDialog, 0)
            }}
          >
            Redeem Coupon Code
          </BreakoutButton>
        </div>
      </Dialog>
    )
  }
)

export const EnrollWithSectionPassDialog = observer(
  function EnrollWithSectionPassDialog({
    cubit,
    usesStripePurchase,
    usesCouponCodes,
  }: {
    cubit: StudentAssignmentCubit
    usesStripePurchase: boolean
    usesCouponCodes: boolean
  }) {
    const repo = useRepository()
    const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'coupon'>(
      usesStripePurchase ? 'stripe' : 'coupon'
    )
    const [couponCode, setCouponCode] = useState('')
    const [formError, setFormError] = useState<string>()
    const [isRedeeming, setIsRedeeming] = useState(false)
    const { popDialog } = useDialogs()
    const { t, tt } = useTranslationTyped()
    const [waitingForStripeURL, setWaitingForStripeURL] = useState(false)

    const tokensRequired = cubit.libraryObject.sectionPassPriceCents
    const totalPrice = (Math.max(tokensRequired, 0) / 100).toFixed(2)

    useEffect(() => {
      cubit.onDialogMountUnmount('mount', 'enroll')
      return () => {
        cubit.onDialogMountUnmount('unmount', 'enroll')
      }
    }, [cubit])

    const redeemCoupon = useCallback(
      async (code: string) => {
        setFormError(undefined)
        if (!code) return setFormError('coupon code required')
        setIsRedeeming(true)
        repo.logEvent('redeem_coupon_button_clicked')
        try {
          const result = await cubit.redeemSectionPassCoupon(code)
          if (typeof result === 'string') setFormError(result)
          const redeemed = result === true
          if (!redeemed && typeof result !== 'string')
            setFormError('invalid coupon code')
          if (redeemed) {
            toast.success('Coupon Redeemed')
            popDialog()
          }
        } catch (e) {
          repo.logEvent('redeem_coupon_button_failed')
          setFormError('invalid coupon code')
        } finally {
          setIsRedeeming(false)
        }
      },
      [cubit, popDialog, repo]
    )

    useEffect(() => {
      repo.logEvent('enroll_with_section_pass_dialog_opened')
    }, [repo])

    const showRadioToggle = usesStripePurchase && usesCouponCodes

    const slideDecks = useMemo(() => {
      // Dedupe slide decks, incase there are multiple assignments with the same slide deck
      const slideDecksMap = Object.fromEntries(
        cubit.sectionAssignmentsWithSlideDecks
          .filter(({ slideDeck }) => slideDeck)
          .map(({ slideDeck }) => [slideDeck!.id, slideDeck as SlideDeck])
      )
      return Object.values(slideDecksMap)
    }, [cubit.sectionAssignmentsWithSlideDecks])

    return (
      <Dialog
        size="lg"
        className="flex flex-col items-center"
        innerClassName="flex flex-col h-full min-h-[650px]"
        ignoreBackdropClick
      >
        <DialogCloseButton />
        <DialogTopSectionWithIcon
          ColoredIcon={
            <TicketIcon size={44} className="stroke-breakout-yellow" />
          }
          title={tt.student_assignment.enroll_with_section_pass()}
          description={tt.student_assignment.enroll_with_section_pass_description()}
        />

        <div className="flex-1">
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex items-center justify-between rounded-2xl bg-core-secondary px-4 py-3">
              <div className="flex flex-col">
                <div className="text-title-small">
                  {cubit.section.data.className}
                </div>
                <div className="text-body-medium text-on-surface-var">
                  {cubit.section.data.sectionName}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <BreakoutUserAvatar
                  user={cubit.section.instructor}
                  radius={16}
                />
                <div className="text-title-small">
                  {cubit.section.instructor.fullName}
                </div>
              </div>
            </div>
            <div className="max-h-[200px] overflow-y-auto">
              {slideDecks.map((slideDeck) => (
                <DisplayedSlideDeck key={slideDeck.id} slideDeck={slideDeck} />
              ))}
            </div>
          </div>
        </div>

        {showRadioToggle && (
          <div className="mt-6">
            <div className="mb-2 text-label-large">
              {t('student_assignment.select_a_payment_method')}
            </div>
            <div
              className="flex gap-2"
              role="radiogroup"
              aria-label="Payment method selection"
            >
              <BreakoutRadioButton
                label={t('student_assignment.pay_using_stripe')}
                checked={paymentMethod === 'stripe'}
                onChange={() => setPaymentMethod('stripe')}
                name="payment-method"
                value="stripe"
              />
              <BreakoutRadioButton
                label={t('student_assignment.access_code')}
                checked={paymentMethod === 'coupon'}
                onChange={() => setPaymentMethod('coupon')}
                name="payment-method"
                value="coupon"
              />
            </div>
          </div>
        )}

        <div
          className={classNames(
            'mt-6 flex gap-4',
            usesStripePurchase && !usesCouponCodes
              ? 'justify-center'
              : 'justify-between'
          )}
        >
          {paymentMethod === 'coupon' ? (
            <div className="flex flex-1 flex-col">
              <BreakoutTextInput
                kind="secondary"
                disabled={isRedeeming}
                placeholder={t('student_assignment.enter_access_code')}
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value)
                  setFormError(undefined)
                }}
                error={
                  formError
                    ? { message: formError, type: 'validate' }
                    : undefined
                }
              />
            </div>
          ) : (
            <div />
          )}
          <BreakoutButton
            size="large"
            kind="accent"
            loading={isRedeeming || waitingForStripeURL}
            onClick={async () => {
              if (paymentMethod === 'coupon') {
                repo.logEvent('redeem_coupon_button_clicked')
                await redeemCoupon(couponCode)
              } else {
                repo.logEvent('purchase_tokens_button_clicked')
                setWaitingForStripeURL(true)
                try {
                  await cubit.createPayment(1, cubit.section.id, popDialog)
                } catch (e) {
                  toast.error(t('student_assignment.pay_with_stripe_failure'))
                  popDialog()
                  throw e
                }
              }
            }}
          >
            {paymentMethod === 'coupon'
              ? t('student_assignment.continue')
              : t('student_assignment.pay_amount_with_stripe', {
                  price: totalPrice,
                })}
          </BreakoutButton>
        </div>
      </Dialog>
    )
  }
)

const DisplayedSlideDeck = observer(function DisplayedSlideDeck({
  slideDeck,
}: {
  slideDeck: SlideDeck
}) {
  return (
    <div className="mx-5 border-b border-border-grey py-3">
      <div className="flex items-start gap-3">
        <div className="bg-surface-container-high mt-0.5 flex h-6 w-6 items-center justify-center rounded-full">
          {slideDeck?.data.slideDeckImageURL && (
            <img
              src={slideDeck.data.slideDeckImageURL}
              alt={slideDeck.data.slideDeckName}
              className="max-h-[50px] w-auto"
            />
          )}
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-label-medium">
            {slideDeck?.data.slideDeckName}
          </div>
          <div className="text-body-medium text-on-surface-var">
            {slideDeck?.data.slideDeckName}
          </div>
        </div>
      </div>
    </div>
  )
})
