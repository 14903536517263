import { activeTools, QAToolsContext } from './hooks'
import { observer } from 'mobx-react-lite'
import { QATools } from './QATools'

export const QAToolsProvider = observer(function QAToolsProvider({
  children,
  enabled,
}: {
  children: React.ReactNode
  enabled: boolean
}) {
  if (!enabled) return children

  return (
    <QAToolsContext.Provider value={activeTools}>
      <QATools />
      {children}
    </QAToolsContext.Provider>
  )
})
