import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { activeTools } from './hooks'

export const QATools = observer(function QATools() {
  const [open, setOpen] = useState(false)
  const tools = activeTools.tools

  useEffect(() => {
    // listen to shift-Q to toggle the QA tools
    const listener = (e: KeyboardEvent) => {
      // detect that we're not in an input field
      if (e.target instanceof HTMLInputElement) return
      if (e.target instanceof HTMLTextAreaElement) return

      if (e.key === 'Q' && e.shiftKey) {
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [tools])

  if (tools.size === 0) return null

  const toolsArray = Array.from(tools)

  return (
    <div className="fixed left-0 top-0 z-50">
      <div className="flex max-h-full flex-col rounded-br-xl bg-white  shadow-lg">
        {!open && (
          <div
            className="cursor-pointer p-2 text-label-large"
            onClick={() => setOpen(!open)}
          >
            QA Tools
          </div>
        )}
        {open && (
          <div className="flex max-h-screen flex-col flex-wrap text-body-medium">
            <div
              className="cursor-pointer p-2 text-label-large"
              onClick={() => setOpen(!open)}
            >
              QA Tools
            </div>
            {toolsArray.map(([name, { label, callback }]) => (
              <a
                className="cursor-pointer p-2 hover:bg-gray-300"
                key={name}
                onClick={() => {
                  const keepOpen = callback()
                  if (keepOpen) return
                  setOpen(false)
                }}
              >
                {label}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  )
})
