import { ConfirmationDialog } from '../components/dialogs/ConfirmationDialog'
import { noTry, noTryAsync } from '@breakoutlearning/firebase-repository/util'
import type { useDialogs } from '../hooks/dialogs'

/**
 * Displays a confirmation dialog and returns a promise that resolves with the user's choice.
 * Resolves `true` if confirmed, `false` if dismissed, or rejects on error.
 */
export function getDialogConfirmation<T>(
  args: Omit<Parameters<typeof ConfirmationDialog>[0], 'onConfirm'> & {
    showDialog: ReturnType<typeof useDialogs>['showDialog']
    dismiss: () => void
    onConfirm: () => Promise<T>
  }
): Promise<{ confirmed: true; value: T } | { confirmed: false }> {
  return new Promise((resolve, reject) => {
    const onConfirm = async () => {
      const [res, err] = await noTryAsync(() => args.onConfirm())
      if (err) return reject(err)
      resolve({ confirmed: true, value: res })
    }

    const dismiss = () => {
      const [, err] = noTry(() => dismiss())
      if (err) reject(err)
      resolve({ confirmed: false })
    }

    args.showDialog(() => (
      <ConfirmationDialog {...args} onConfirm={onConfirm} dismiss={dismiss} />
    ))
  })
}
