export function DialogTopSectionWithIcon({
  ColoredIcon,
  title,
  description,
}: {
  ColoredIcon: React.ReactNode
  title: string
  description: string
}) {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 flex flex-row justify-center">{ColoredIcon}</div>
      <div className="mb-2 text-center text-headline-large">{title}</div>
      <div className="mb-6 max-w-[500px] whitespace-pre-line text-center text-body-large text-on-surface-var">
        {description}
      </div>
    </div>
  )
}
