import type { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useRepository } from 'hooks/auth'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { DateTime } from 'luxon'
import { AssignmentState } from '@breakoutlearning/firebase-repository/types'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { toast } from 'react-hot-toast'
import { ErrorIcon } from 'components/icons/Error'
import { ConfirmationDialog } from 'components/dialogs/ConfirmationDialog'
import { CancelExperienceConfirmationDialog } from 'pages/instructor/assignment/dialogs/CancelExperienceConfirmationDialog'
import { useTranslationTyped } from 'i18n/i18n'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import {
  BaseAssignmentDialog,
  GROUPING_SIZE_MAXIMUM_DEFAULT,
} from 'pages/instructor/slide_deck/AssignDialogV3/BaseAssignmentDialog'
import { getAssignmentSchema } from 'pages/instructor/slide_deck/AssignDialogV3/schemas'
import type { AssignmentFormValues } from 'pages/instructor/slide_deck/AssignDialogV3/schemas'
import { deleteField } from 'firebase/firestore'

export const EditAssignmentDialog = observer(function EditAssignmentDialog({
  assignment,
  cubit,
}: {
  assignment: SectionAssignment
  cubit: InstructorAssignmentCubit
}) {
  const { tt } = useTranslationTyped()
  const { popDialog } = useDialogs()
  const rootStore = useRootStore()
  const { allowDraftAssignments, showNewScheduling } =
    useRepository().featureFlags.data
  const scalarsRef =
    useRef<SectionAssignment['data']['assignmentGradingScalars']>(undefined)
  const { canvasMode: ltiMode } = useCanvasMode()
  const [confirmDialogToShow, setConfirmDialogToShow] = useState<
    'cancel' | 'delete' | undefined
  >()

  const hasBeenStarted = cubit.hasBeenStarted
  const releasedToStudents =
    cubit.section.data.userIds.length > 0 && assignment.data.assignmentState > 0
  const initialGroupingType = assignment.groupingType
  const initialGroupingSize = assignment.data.groupingSize
  const hasQuestions = cubit.questionsCollection.models.some((q) => q.isGraded)
  const hasRubrics = cubit.slideRubrics.models.length > 0
  const isDraft = assignment.data.assignmentState <= AssignmentState.draft

  const schema = useMemo(() => {
    const expiresAt = assignment.data.expiresAt
    return getAssignmentSchema({
      tt,
      minDateOverride:
        cubit.hasBeenStarted && expiresAt
          ? DateTime.fromJSDate(expiresAt)
          : undefined,
    })
  }, [assignment.data.expiresAt, cubit.hasBeenStarted, tt])

  const form = useForm<AssignmentFormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      catalogId: assignment.data.catalogId ?? undefined,
      assignedAt: assignment.assignedAt
        ? DateTime.fromJSDate(assignment.assignedAt)
        : undefined,
      expiresAt: assignment.expiresAt
        ? DateTime.fromJSDate(assignment.expiresAt)
        : undefined,
      assignmentType: assignment.assignmentType,
      groupingType: assignment.groupingType,
      sectionId: assignment.sectionId,
      groupingSize: assignment.data.groupingSize,
      groupingSizeMaximum:
        assignment.data.groupingSizeMaximum ?? GROUPING_SIZE_MAXIMUM_DEFAULT,
      configureGrading: assignment.data.assignmentGradingScalars !== undefined,
    },
  })

  const onSubmit = useCallback(
    async (data: Partial<AssignmentFormValues>) => {
      const assignmentGradingScalars = data.configureGrading
        ? scalarsRef.current
        : undefined

      const lastGroupingMax =
        assignment.data.groupingSizeMaximum || GROUPING_SIZE_MAXIMUM_DEFAULT

      const groupingType = data.groupingType
      const newGroupingMax =
        data.groupingSizeMaximum ?? GROUPING_SIZE_MAXIMUM_DEFAULT

      const updateGroupingSizeMax =
        lastGroupingMax !== newGroupingMax &&
        groupingType === AssignmentGroupingType.manual

      const [, err] = await noTryAsync(() =>
        cubit.updateAssignment({
          groupingType: data.groupingType,
          assignedAt: data.assignedAt ?? deleteField(),
          expiresAt: data.expiresAt ?? deleteField(),
          ...(groupingType !== initialGroupingType && { groupingType }),
          ...(groupingType === AssignmentGroupingType.automaticRandom &&
            data.groupingSize !== initialGroupingSize && {
              groupingSize: data.groupingSize,
            }),
          ...(updateGroupingSizeMax && {
            groupingSizeMaximum: newGroupingMax,
          }),
          assignmentGradingScalars: assignmentGradingScalars ?? deleteField(),
        })
      )
      if (err) {
        toast.error(tt.instructor_assignment.save_failed())
        throw err
      }
      popDialog()
    },
    [
      assignment.data.groupingSizeMaximum,
      cubit,
      initialGroupingSize,
      initialGroupingType,
      popDialog,
      tt.instructor_assignment,
    ]
  )

  const earlySecondaryButtonOverride = useMemo(() => {
    if (releasedToStudents) {
      if (allowDraftAssignments) {
        return {
          text: tt.instructor_assignment.cancel_experience(),
          onClick: async () => {
            setConfirmDialogToShow('cancel')
          },
          kind: 'error' as const,
        }
      } else {
        return undefined // Canceling experiences is under the allowDraftAssignments feature flag
      }
    } else {
      return {
        text: tt.instructor_assignment.delete_assignment(),
        onClick: async () => {
          setConfirmDialogToShow('delete')
        },
        kind: 'error' as const,
      }
    }
  }, [
    allowDraftAssignments,
    releasedToStudents,
    setConfirmDialogToShow,
    tt.instructor_assignment,
  ])

  return (
    <>
      <BaseAssignmentDialog
        form={form}
        onSubmit={onSubmit}
        onSubmitLTIOverride={undefined}
        releasedToStudents={releasedToStudents}
        hasBeenStarted={hasBeenStarted}
        hasQuestions={hasQuestions}
        hasRubrics={hasRubrics}
        submitButtonText={tt.instructor_class.save()}
        showNewScheduling={showNewScheduling}
        ltiMode={ltiMode}
        fullScreen={ltiMode}
        scalarsRef={scalarsRef}
        initialAssignmentGradingScalars={
          assignment.data.assignmentGradingScalars
        }
        isDraft={isDraft}
        earlySecondaryButtonOverride={earlySecondaryButtonOverride}
      />

      <ConfirmationDialog
        secondaryButtonText={tt.instructor_assignment.cancel()}
        buttonText={tt.instructor_assignment.delete()}
        btnTestId={'delete-button-confirm'}
        open={confirmDialogToShow === 'delete'}
        buttonKind="error"
        topIcon={<ErrorIcon size={36} className="text-core-error" />}
        dismiss={() => {
          setConfirmDialogToShow(undefined)
        }}
        text={tt.instructor_assignment.delete_assignment_question()}
        subtitle={
          <p className="whitespace-pre-line text-body-large">
            {tt.instructor_assignment.confirm_delete_assignment()}
          </p>
        }
        inline={true}
        onConfirm={async () => {
          cubit.dispose()
          cubit.deleteSectionAssignment().then(() => {
            toast.success(tt.instructor_assignment.assignment_deleted())
          })
          rootStore.navigateTo('instructorClass', {
            id: cubit.sectionId,
          })
        }}
      />

      <CancelExperienceConfirmationDialog
        close={() => setConfirmDialogToShow(undefined)}
        open={confirmDialogToShow === 'cancel'}
        onConfirm={async () => {
          await cubit.cancelSectionAssignment()
          toast.success(tt.instructor_assignment.assignment_canceled())
          rootStore.navigateTo('instructorClass', {
            id: cubit.sectionId,
          })
        }}
      />
    </>
  )
})
