import { SpinnerWithLogo } from 'components/Spinner'
import { useRootStore } from 'hooks/rootStore'
import { useQueryParams } from 'hooks/router'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { z } from 'zod'

const payloadSchema = z.object({
  id_token: z.string().optional(),
  endpoint: z.string().optional(),
})

export const LtiLoaderPage = observer(function LtiLoaderPage() {
  const rootStore = useRootStore()
  const rawParams = useQueryParams()
  const parsed = payloadSchema.safeParse(rawParams)
  const params = parsed.data
  const id_token = params?.id_token
  const endpoint = params?.endpoint

  useEffect(() => {
    if (!endpoint) return
    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ id_token_resent: id_token, from_loader: true }),
      redirect: 'error',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (res) => {
        const body = await res.json()
        const url = new URL(body.url)

        const target = url.pathname + url.search

        rootStore.navigateToUrl(target)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [id_token, endpoint, rootStore])

  return (
    <div className="lti-configuration min-h-screen w-screen">
      <div className="">
        <img
          src="/assets/images/logo.png"
          width={64}
          className="absolute right-8 top-8"
          alt="Breakout Learning"
        />
      </div>
      <div className="lti-door flex min-h-screen w-full flex-col items-center justify-center">
        <SpinnerWithLogo />
      </div>
      <form action={endpoint?.toString()} method="post" id="ltiLaunchForm">
        <input
          type="hidden"
          name="id_token_resent"
          value={id_token?.toString()}
        />
        <input type="hidden" name="from_loader" value="true" />
      </form>
    </div>
  )
})
