import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'
import type {
  Control,
  FieldError,
  UseFormReturn,
  UseFormSetValue,
} from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { AssignmentType } from '@breakoutlearning/firebase-repository/types'
import type { ButtonKind } from 'components/design-system/BreakoutButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutRadioButton } from 'components/design-system/BreakoutRadioButton'
import { GroupingMethodDetails } from '../GroupingMethodDetails'
import { ConfigureGradingScalars } from '../../assignment/dialogs/ConfigureGradingScalars'
import { useTranslationTyped } from 'i18n/i18n'
import { InfoIcon } from 'components/icons/Info'
import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import classNames from 'classnames'
import { FormError } from 'components/design-system/form/FormError'
import { AdminOnlyRing } from 'components/AdminOnlyRing'
import { useBreakoutUser } from 'hooks/profile'
import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { useDialogs } from 'hooks/dialogs'
import type {
  AssignmentFormValues,
  LtiAssignmentFormValues,
} from 'pages/instructor/slide_deck/AssignDialogV3/schemas'
import { getLtiAssignmentSchema } from 'pages/instructor/slide_deck/AssignDialogV3/schemas'
import type { DateTime } from 'luxon'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'

export const GROUPING_SIZE_MAXIMUM_DEFAULT = 5

const FormStage = {
  Assignment: 'assignment',
  Grading: 'grading',
} as const
type FormStage = (typeof FormStage)[keyof typeof FormStage]

export const BaseAssignmentDialog = ({
  className,
  earlySecondaryButtonOverride,
  form,
  fullScreen = false,
  hasBeenStarted,
  hasQuestions,
  hasRubrics,
  headerTitle,
  hideDateFields = false,
  initialAssignmentGradingScalars,
  isDraft = false,
  ltiMode = false,
  onSubmitLTIOverride,
  onSubmit,
  releasedToStudents,
  scalarsRef,
  sectionSelectComponent,
  showNewScheduling,
  submitButtonText,
}: {
  className?: string
  earlySecondaryButtonOverride?: {
    text: string
    kind?: ButtonKind
    onClick: () => Promise<void>
  }
  form: UseFormReturn<AssignmentFormValues>
  fullScreen?: boolean
  hasBeenStarted: boolean
  hasQuestions: boolean
  hasRubrics: boolean
  headerTitle?: string
  hideDateFields?: boolean
  initialAssignmentGradingScalars?: SectionAssignment['data']['assignmentGradingScalars']
  isDraft?: boolean
  ltiMode?: boolean
  onSubmitLTIOverride?: (data: LtiAssignmentFormValues) => Promise<void>
  onSubmit: (data: AssignmentFormValues) => Promise<void>
  releasedToStudents: boolean
  scalarsRef: React.RefObject<
    SectionAssignment['data']['assignmentGradingScalars']
  >
  sectionSelectComponent?: ReactNode
  showNewScheduling: boolean
  submitButtonText: string
}) => {
  const [stage, setStage] = useState<FormStage>(FormStage.Assignment)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingEarlySecondary, setIsLoadingEarlySecondary] = useState(false)
  const { tt } = useTranslationTyped()
  const user = useBreakoutUser()
  const { popDialog } = useDialogs()

  const [{ gradingSumValid, gradingSum }, setGradingSum] = useState<{
    gradingSumValid: boolean
    gradingSum: number
  }>({ gradingSumValid: false, gradingSum: 0 })

  const setGradingSumCallback = useCallback(
    ({ isValid, sumAsInt }: { isValid: boolean; sumAsInt: number }) => {
      setGradingSum({
        gradingSum: sumAsInt,
        gradingSumValid: isValid,
      })
    },
    []
  )

  const {
    control,
    setValue,
    formState: { isSubmitting, errors },
    trigger,
    handleSubmit,
    watch,
  } = form

  const configureGrading = watch('configureGrading')
  const groupingType = watch('groupingType')
  const expiresAt = watch('expiresAt')
  const assignedAt = watch('assignedAt')

  return (
    <Dialog
      size={fullScreen ? 'xxl' : 'xl'}
      testId="assign-class-dialog"
      className={classNames(
        'flex h-[700px] w-[1000px] items-center justify-center !overflow-hidden',
        {
          'bg-white': fullScreen,
        },
        className
      )}
      innerClassName="flex flex-col h-full w-full items-center gap-4 !min-h-0"
    >
      <DialogCloseButton />
      <div
        className={classNames('flex flex-col gap-1 text-center', {
          'mb-4': fullScreen,
        })}
      >
        <strong className="text-body-large text-on-surface-var">
          {stage === FormStage.Assignment
            ? tt.instructor_library.step_1()
            : tt.instructor_library.step_2()}
        </strong>
        <h2 className="text-headline-large">
          {headerTitle ||
            (stage === FormStage.Assignment
              ? tt.instructor_library.configure_assignment()
              : tt.instructor_library.configure_grading())}
        </h2>
      </div>

      <form
        className="flex min-h-0 w-[450px] flex-grow flex-col gap-4"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex w-full flex-grow flex-col gap-1 overflow-y-auto px-0.5">
          {sectionSelectComponent && stage === FormStage.Assignment
            ? sectionSelectComponent
            : null}

          {ltiMode && errors?.expiresAt && (
            <FormError error={errors.expiresAt as FieldError} />
          )}
          {ltiMode && errors?.assignedAt && (
            <FormError error={errors.assignedAt as FieldError} />
          )}

          {stage === FormStage.Assignment && (
            <AssignmentInputs
              control={control}
              setValue={setValue}
              isSubmitting={isSubmitting}
              hasBeenStarted={hasBeenStarted}
              ltiMode={ltiMode}
              isDraft={isDraft}
              assignedAt={assignedAt}
              releasedToStudents={releasedToStudents}
              groupingType={groupingType}
              showNewScheduling={showNewScheduling}
              hideDateFields={hideDateFields}
              expiresAt={expiresAt}
              user={user}
            />
          )}

          {stage === FormStage.Grading && (
            <GradingInputs
              setValue={setValue}
              configureGrading={configureGrading}
              releasedToStudents={releasedToStudents}
              hasQuestions={hasQuestions}
              hasRubrics={hasRubrics}
              scalarsRef={scalarsRef}
              initialAssignmentGradingScalars={initialAssignmentGradingScalars}
              setGradingSumCallback={setGradingSumCallback}
              gradingSumValid={gradingSumValid}
              gradingSum={gradingSum}
            />
          )}
        </div>

        <div className="flex flex-col justify-end">
          <div className="flex flex-row justify-between">
            {stage === FormStage.Assignment && (
              <>
                <BreakoutButton
                  size="medium"
                  type="button"
                  disabled={isLoading}
                  className="mt-2"
                  kind={earlySecondaryButtonOverride?.kind ?? 'secondary'}
                  data-testid="assign-class-secondary-button"
                  onClick={
                    earlySecondaryButtonOverride
                      ? async () => {
                          setIsLoadingEarlySecondary(true)
                          await earlySecondaryButtonOverride?.onClick()
                          setIsLoadingEarlySecondary(false)
                        }
                      : popDialog
                  }
                  loading={isLoadingEarlySecondary}
                >
                  {earlySecondaryButtonOverride?.text ??
                    tt.instructor_library.cancel()}
                </BreakoutButton>
                <BreakoutButton
                  disabled={isLoadingEarlySecondary}
                  data-testid="assign-class-continue-button"
                  size="medium"
                  type="button"
                  className="mt-2"
                  kind="accent"
                  onClick={async () => {
                    setIsLoading(true)
                    const isValid = await trigger(undefined, {
                      shouldFocus: true,
                    })
                    setIsLoading(false)
                    if (isValid) {
                      setStage(FormStage.Grading)
                    }
                  }}
                  loading={isLoading}
                >
                  {tt.instructor_library.continue()}
                </BreakoutButton>
              </>
            )}
            {stage === FormStage.Grading && (
              <>
                <BreakoutButton
                  size="medium"
                  type="button"
                  disabled={isLoading}
                  className="mt-2"
                  kind="secondary"
                  onClick={() => {
                    setStage(FormStage.Assignment)
                  }}
                >
                  {tt.instructor_library.back()}
                </BreakoutButton>
                <BreakoutButton
                  size="medium"
                  type="button"
                  kind="accent"
                  className="mt-2"
                  disabled={
                    configureGrading === undefined ||
                    (configureGrading && !gradingSumValid) ||
                    isLoading
                  }
                  onClick={async (e) => {
                    e.preventDefault()
                    setIsLoading(true)

                    if (ltiMode && onSubmitLTIOverride) {
                      const values = form.getValues()
                      const result = getLtiAssignmentSchema({ tt }).safeParse(
                        values
                      )
                      if (result.success) {
                        return await onSubmitLTIOverride(result.data).finally(
                          () => {
                            setIsLoading(false)
                          }
                        )
                      } else {
                        result.error.errors.forEach((error) => {
                          form.setError(
                            error.path[0] as keyof LtiAssignmentFormValues,
                            {
                              type: 'custom',
                              message: error.message,
                            }
                          )
                        })
                        setIsLoading(false)
                      }
                    } else {
                      return await handleSubmit((data) =>
                        onSubmit(data)
                      )().finally(() => {
                        setIsLoading(false)
                      })
                    }
                  }}
                  data-testid="assign-class-button"
                  loading={isSubmitting}
                >
                  {submitButtonText}
                </BreakoutButton>
              </>
            )}
          </div>
        </div>
      </form>
    </Dialog>
  )
}

const AssignmentInputs = ({
  control,
  setValue,
  isSubmitting,
  hasBeenStarted,
  ltiMode,
  isDraft,
  assignedAt,
  releasedToStudents,
  groupingType,
  showNewScheduling,
  hideDateFields,
  expiresAt,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  user,
}: {
  control: Control<AssignmentFormValues>
  setValue: UseFormSetValue<AssignmentFormValues>
  isSubmitting: boolean
  hasBeenStarted: boolean
  ltiMode: boolean
  isDraft: boolean
  assignedAt: DateTime
  releasedToStudents: boolean
  groupingType: AssignmentGroupingType
  showNewScheduling: boolean
  hideDateFields: boolean
  expiresAt: DateTime
  user: BreakoutUser
}) => {
  const { tt } = useTranslationTyped()

  return (
    <>
      <AdminOnlyRing label="" ringClassName="mt-2" onlyShowIf={false}>
        <Controller
          control={control}
          name="assignmentType"
          render={({ field, fieldState }) => (
            <BreakoutSelect
              {...field}
              error={fieldState.error}
              required
              kind="secondary"
              label={tt.instructor_library.assignment_type()}
              name="assignmentType"
              options={[
                {
                  value: AssignmentType.ManyGroups,
                  label: tt.instructor_library.assignment_type_student_led(),
                },
                {
                  value: AssignmentType.OneGroup,
                  label: tt.instructor_library.assignment_type_instructor_led(),
                },
              ]}
            />
          )}
        />
      </AdminOnlyRing>

      {!hideDateFields && (
        <>
          <BreakoutTooltip
            enabled={hasBeenStarted || ltiMode}
            content={
              ltiMode
                ? tt.instructor_assignment.disabled_because_lti()
                : tt.instructor_assignment.disabled_because_its_started()
            }
          >
            <div className={ltiMode ? 'hidden' : ''}>
              <Controller
                control={control}
                name="assignedAt"
                render={({ field, fieldState }) => (
                  <BreakoutDateTimeInput
                    {...field}
                    disabled={isSubmitting || hasBeenStarted}
                    error={fieldState.error}
                    type="datetime-local"
                    label={tt.instructor_assignment.available_from()}
                    required={!isDraft}
                    kind="secondary"
                    id="assignment-start-time"
                    hideNowButton
                    onChange={(newAssignedAt) => {
                      field.onChange(newAssignedAt)
                    }}
                    resolution="15min"
                  />
                )}
              />
            </div>
          </BreakoutTooltip>
          <BreakoutTooltip
            enabled={ltiMode}
            content={tt.instructor_assignment.disabled_because_lti()}
          >
            <div className={ltiMode ? 'hidden' : ''}>
              <Controller
                control={control}
                name="expiresAt"
                render={({ field, fieldState }) => {
                  return (
                    <BreakoutDateTimeInput
                      {...field}
                      error={fieldState.error}
                      type="datetime-local"
                      label={tt.instructor_assignment.due_date()}
                      required={!isDraft}
                      kind="secondary"
                      id="meeting-time"
                      hideNowButton
                      min={assignedAt?.plus({ days: 1 })}
                      max={assignedAt?.plus({ days: 365 })}
                      initialView={assignedAt?.plus({ days: 1 })}
                      value={field.value}
                      onChange={field.onChange}
                      resolution="15min"
                    />
                  )
                }}
              />
            </div>
          </BreakoutTooltip>
        </>
      )}

      <BreakoutTooltip
        enabled={releasedToStudents}
        content={tt.instructor_assignment.disabled_because_it_has_students_in_section()}
      >
        <div>
          <Controller
            control={control}
            name="groupingType"
            render={({ field }) => (
              <BreakoutSelect
                inputClassName="h-[50px]"
                {...field}
                required
                label={tt.instructor_library.grouping_method()}
                name="groupingType"
                kind="secondary"
                disabled={isSubmitting || releasedToStudents}
                onChange={(value) => {
                  field.onChange(value)
                  // undefined is default value for manual grouping if not using new scheduling
                  if (value === AssignmentGroupingType.manual) {
                    setValue('groupingSize', undefined)
                    setValue(
                      'groupingSizeMaximum',
                      GROUPING_SIZE_MAXIMUM_DEFAULT
                    )
                  } else {
                    setValue('groupingSize', GROUPING_SIZE_MAXIMUM_DEFAULT)
                  }
                }}
                options={[
                  {
                    value: AssignmentGroupingType.manual,
                    label: tt.instructor_library.students_self_grouping(),
                  },
                  {
                    value: AssignmentGroupingType.automaticRandom,
                    label:
                      tt.instructor_library.automatic_randomized_grouping(),
                  },
                ]}
              />
            )}
          />
        </div>
      </BreakoutTooltip>

      {groupingType === AssignmentGroupingType.automaticRandom && (
        <BreakoutTooltip
          enabled={releasedToStudents}
          content={tt.instructor_assignment.disabled_because_it_has_students_in_section()}
        >
          <div>
            <Controller
              control={control}
              name="groupingSize"
              disabled={releasedToStudents}
              render={({ field, fieldState }) => (
                <>
                  <BreakoutTextInput
                    {...field}
                    disabled={releasedToStudents}
                    kind="secondary"
                    error={fieldState.error}
                    type="number"
                    label={tt.instructor_library.max_number_of_students()}
                    name="groupingSize"
                    min="2"
                    max={RoomState.maxAllowedUsers}
                    required
                  />
                  <strong className="pl-4 text-body-small text-on-surface-disabled">
                    {tt.instructor_library.max_number_of_students_description()}
                  </strong>
                </>
              )}
            />
          </div>
        </BreakoutTooltip>
      )}

      {showNewScheduling &&
        groupingType !== AssignmentGroupingType.automaticRandom && (
          <BreakoutTooltip
            enabled={releasedToStudents}
            content={tt.instructor_assignment.disabled_because_it_has_students_in_section()}
          >
            <Controller
              control={control}
              name="groupingSizeMaximum"
              disabled={releasedToStudents}
              render={({ field, fieldState }) => (
                <>
                  <BreakoutTextInput
                    {...field}
                    disabled={releasedToStudents}
                    kind="secondary"
                    error={fieldState.error}
                    type="number"
                    label={tt.instructor_library.max_number_of_students()}
                    // if we are in manual mode, set the max to the max allowed users
                    // for automatic random this input is simply desired group size (not cap)
                    name="groupingSizeMaximum"
                    min="2"
                    max={RoomState.maxAllowedUsers}
                    required
                  />

                  <strong className="pl-4 text-body-small text-on-surface-disabled">
                    {tt.instructor_library.max_number_of_students_description()}
                  </strong>
                </>
              )}
            />
          </BreakoutTooltip>
        )}

      {!releasedToStudents && (
        <GroupingMethodDetails
          groupingMethod={groupingType}
          expiresAt={expiresAt}
          assignedAt={assignedAt}
        />
      )}
    </>
  )
}

const GradingInputs = ({
  setValue,
  configureGrading,
  releasedToStudents,
  hasQuestions,
  hasRubrics,
  scalarsRef,
  initialAssignmentGradingScalars,
  setGradingSumCallback,
  gradingSumValid,
  gradingSum,
}: {
  setValue: UseFormSetValue<AssignmentFormValues>
  configureGrading: boolean | undefined
  releasedToStudents: boolean
  hasQuestions: boolean
  hasRubrics: boolean
  scalarsRef: React.RefObject<
    SectionAssignment['data']['assignmentGradingScalars']
  >
  initialAssignmentGradingScalars: SectionAssignment['data']['assignmentGradingScalars']
  setGradingSumCallback: (gradingSum: {
    isValid: boolean
    sumAsInt: number
  }) => void
  gradingSumValid: boolean
  gradingSum: number
}) => {
  const { tt } = useTranslationTyped()

  return (
    <>
      <h3 className="mb-1 text-title-medium">
        {tt.instructor_library.grade_your_students()}
      </h3>
      <>
        <div className="flex w-full flex-row gap-1">
          <BreakoutRadioButton
            data-testid="configure-grading-true"
            disabled={releasedToStudents}
            checked={configureGrading === true}
            label={tt.instructor_library.yes()}
            value={undefined}
            onChange={() => {
              setValue('configureGrading', true)
            }}
          />
          <BreakoutRadioButton
            data-testid="configure-grading-false"
            disabled={releasedToStudents}
            checked={configureGrading === false}
            label={tt.instructor_library.no()}
            value={undefined}
            onChange={() => {
              setValue('configureGrading', false)
            }}
          />
        </div>
        <FormError
          error={
            configureGrading === undefined
              ? {
                  message: tt.instructor_library.grading_required(),
                  type: 'custom',
                }
              : undefined
          }
        />
      </>
      <div className="mt-3 flex w-full">
        {configureGrading === false && (
          <p className="text-body-large text-on-surface-var">
            {tt.instructor_library.no_grading_description()}
          </p>
        )}
        {configureGrading && (
          <div className="flex w-full flex-col gap-3">
            <h3 className="border-b border-outline-variant pb-2 text-title-medium">
              {tt.instructor_library.configure_scalars_title()}
            </h3>
            <ConfigureGradingScalars
              disableAll={releasedToStudents}
              hasQuizQuestions={hasQuestions}
              hasRubrics={hasRubrics}
              ref={scalarsRef}
              defaults={initialAssignmentGradingScalars}
              onChange={setGradingSumCallback}
            />
            <div className="flex flex-col gap-1 border-t border-outline-variant pt-2">
              <div className="flex flex-row justify-between">
                <strong className="text-title-medium">
                  {tt.instructor_library.total()}
                </strong>
                <div
                  className={classNames(
                    'flex flex-row items-center gap-1 text-title-medium',
                    {
                      'text-core-error': !gradingSumValid,
                    }
                  )}
                >
                  {!gradingSumValid && <TriangleAlertIcon size={12.5} />}
                  <strong>{gradingSum + '%'}</strong>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 text-label-medium text-on-surface-var">
                <InfoIcon size={15} />
                <span>
                  {tt.instructor_library.select_grading_weights_description()}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
